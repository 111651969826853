/** @jsx jsx */
import { jsx } from 'theme-ui'

import PropTypes from 'prop-types'
import { LiteYouTubeEmbed } from 'react-lite-youtube-embed'

import Markdown from './Markdown'

const VideoTeaser = ({
  as: Tag,
  className,
  headline,
  copy,
  video,
  ...props
}) => {
  const { id: videoID } = video || {}

  return (
    <Tag className={`${className}`} {...props}>
      {headline && (
        <h2 className="text-headline2 leading-none mb-12">● {headline}</h2>
      )}
      <div
        className="relative h-0"
        sx={{
          paddingBottom: '56.25%',
        }}
      >
        <LiteYouTubeEmbed
          id={videoID}
          adNetwork={false}
          playlist={false}
          poster="hqdefault"
          title={headline}
          noCookie={true}
        />
      </div>
      {copy && <Markdown className="mt-6 indent-copy" source={copy} />}
    </Tag>
  )
}

VideoTeaser.propTypes = {
  as: PropTypes.string,
  className: PropTypes.string,
  headline: PropTypes.string,
  copy: PropTypes.string,
  video: PropTypes.object,
}

VideoTeaser.defaultProps = {
  as: 'article',
  className: '',
  headline: null,
  copy: null,
  video: {},
}

export default VideoTeaser
