/** @jsx jsx */
import { jsx } from 'theme-ui'

import PropTypes from 'prop-types'
import GatsbyImage from 'gatsby-image'

import AspectRatio from './AspectRatio'
import Markdown from './Markdown'

const ArticleTeaser = ({
  as: Tag,
  className,
  headline,
  copy,
  image,
  imageAlt,
  ...props
}) => {
  return (
    <Tag className={`${className}`} {...props}>
      <h2 className="font-bold indent-copy">{headline}</h2>
      {image && (
        <AspectRatio width={16} height={9} className="mt-2">
          <GatsbyImage
            className="w-full h-full"
            fluid={image}
            alt={imageAlt}
            imgStyle={{
              objectFit: 'cover',
              objectPosition: '50% 50%',
            }}
          />
        </AspectRatio>
      )}
      <Markdown className="mt-6 indent-copy" source={copy} />
    </Tag>
  )
}

ArticleTeaser.propTypes = {
  as: PropTypes.string,
  className: PropTypes.string,
  headline: PropTypes.string,
  copy: PropTypes.string,
  image: PropTypes.object,
  imageAlt: PropTypes.string,
}

ArticleTeaser.defaultProps = {
  as: 'article',
  className: '',
  headline: null,
  copy: null,
  image: null,
  imageAlt: '',
}

export default ArticleTeaser
