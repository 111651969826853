/** @jsx jsx */
import { jsx } from 'theme-ui'

import { useStaticQuery, graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Link as ScrollLink } from 'react-scroll'

import { useHeaderOffset } from '../hooks/useHeaderOffset'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Markdown from '../components/Markdown'
import ArticleTeaser from '../components/ArticleTeaser'
import VideoTeaser from '../components/VideoTeaser'
import AspectRatio from '../components/AspectRatio'

import { ReactComponent as ArrowDownIcon } from '../images/arrow_down.svg'
import { ReactComponent as FraunhoferSvg } from '../images/fraunhofer.svg'
import { ReactComponent as KicSvg } from '../images/kic.svg'
import { ReactComponent as UniversitatStuttgartSvg } from '../images/universitat_stuttgart.svg'

export default function IndexPage(props) {
  const { t } = useTranslation()
  const headerOffset = useHeaderOffset()

  const orderMailTo = t('home:order.mail.to')
  const orderMailSubject = encodeURIComponent(t('home:order.mail.subject'))
  const orderMailBody = encodeURIComponent(t('home:order.mail.body'))
  const orderUrl = `mailto:${orderMailTo}?subject=${orderMailSubject}&body=${orderMailBody}`

  const assets = useStaticQuery(graphql`
    query IndexPageQuery {
      article0: file(relativePath: { eq: "_DSC4408.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      article1: file(relativePath: { eq: "_DSC0648.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      article2: file(relativePath: { eq: "_DSC0957.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      article3: file(relativePath: { eq: "_DSC0974.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      article4: file(
        relativePath: { eq: "JanekStroisch_coke_Highres_13.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      article5: file(relativePath: { eq: "_R001375.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      digitalakademie_bw: file(relativePath: { eq: "digitalakademie_bw.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const magazineItems = t('home:magazine.items', {
    returnObjects: true,
  })
  const magazineElems = Array.isArray(magazineItems)
    ? magazineItems.map((item, index) => (
        <ArticleTeaser
          key={item.headline}
          headline={item.headline}
          copy={item.copy}
          image={assets['article' + index].childImageSharp.fluid}
        />
      ))
    : []

  const videoItems = t('home:videos.items', {
    returnObjects: true,
  })
  const videoElems = Array.isArray(videoItems)
    ? videoItems.map(item => (
        <VideoTeaser key={item.headline} video={item.video} />
      ))
    : []

  return (
    <Layout {...props}>
      <SEO title="Home" />
      <main className="block">
        <section className="text-white bg-black bg-opacity-90">
          <AspectRatio
            className="mx-auto px-container max-w-container"
            width={16}
            height={9}
            allowOverflow={true}
          >
            <div className="flex flex-col py-24 md:py-36 h-full max-h-screen-80">
              <Markdown
                className="text-headline1 leading-none mb-12"
                source={t('home:intro.headline')}
              />
              <ScrollLink
                to={t('home:information.id')}
                smooth={true}
                offset={headerOffset}
                className="block mx-auto text-white px-4 py-2 box-content mt-auto"
                sx={{ width: '28px' }}
              >
                <AspectRatio width={144} height={202}>
                  <ArrowDownIcon />
                </AspectRatio>
              </ScrollLink>
            </div>
          </AspectRatio>
        </section>
        <section
          className="py-14 md:py-24 bg-yellow bg-opacity-60"
          id={t('home:information.id')}
        >
          <div className="mx-auto px-container max-w-container">
            <h2 className="text-headline2 leading-none text-center">
              {t('home:information.headline')}
            </h2>
            <Markdown
              className="mt-14 md:mt-24 text-headline3 indent-large"
              source={t('home:information.copy')}
            />
          </div>
        </section>
        <section
          className="pb-14 md:pb-24 bg-yellow bg-opacity-60"
          id={t('home:order.id')}
        >
          <div className="mx-auto px-container max-w-container text-center">
            <div>
              <a
                className="inline-block text-action leading-none text-center rounded-full bg-white border-4 border-black border-solid break-words overflow-hidden"
                href={orderUrl}
                sx={{
                  padding: '0.5em 1em',
                }}
              >
                {t('home:order.action.text')}
              </a>
              <Markdown
                className="mt-6 md:mt-9 text-headline3 text-center"
                source={t('home:order.copy')}
              />
            </div>
          </div>
        </section>
        <section className="pt-14 md:pt-24 pb-10" id={t('home:magazine.id')}>
          <div className="mx-auto px-container max-w-container">
            <h2 className="text-headline2 leading-none text-center">
              {t('home:magazine.headline')}
            </h2>
            <div className="mt-14 md:mt-24 grid gap-x-8 gap-y-14 sm:grid-cols-2 md:grid-cols-3">
              {magazineElems}
            </div>
            <Markdown
              className="mt-12 md:mt-18 text-headline3 text-center"
              source={t('home:magazine.note')}
            />
          </div>
        </section>
        <section
          className="py-14 md:py-24 bg-black bg-opacity-5"
          id={t('home:videos.id')}
        >
          <div className="mx-auto px-container max-w-container">
            <div className="grid gap-x-8 gap-y-14 md:grid-cols-3">
              {videoElems}
            </div>
          </div>
        </section>
        <section className="py-14 md:py-24" id={t('home:about.id')}>
          <div className="mx-auto px-container max-w-container">
            <h2 className="text-headline2 leading-none text-center">
              {t('home:about.headline')}
            </h2>
            <Markdown
              className="mt-14 md:mt-24 block text-action leading-none text-center rounded-full bg-yellow bg-opacity-60"
              sx={{
                padding: '0.5em 1em',
              }}
              source={t('home:about.subline')}
            />
            <Markdown
              className="mt-12 text-headline3 indent-large"
              source={t('home:about.copy')}
            />
          </div>
        </section>
        <section
          className="py-14 md:py-24 bg-black bg-opacity-5"
          id={t('home:partners.id')}
        >
          <div className="mx-auto px-container max-w-container">
            <h2 className="text-headline2 leading-none text-center">
              {t('home:partners.headline')}
            </h2>
            <ul
              className="mt-14 md:mt-24 grid gap-x-20 gap-y-14 grid-cols-2 md:grid-cols-4"
              sx={{ gridAutoRows: '126px' }}
            >
              <li>
                <FraunhoferSvg className="w-full h-full" />
              </li>
              <li>
                <KicSvg className="w-full h-full" />
              </li>
              <li>
                <GatsbyImage
                  className="w-full h-full"
                  fluid={assets.digitalakademie_bw.childImageSharp.fluid}
                  alt="digitalakademie@bw"
                  imgStyle={{
                    objectFit: 'contain',
                    objectPosition: '50% 50%',
                  }}
                />
              </li>
              <li>
                <UniversitatStuttgartSvg className="w-full h-full" />
              </li>
            </ul>
          </div>
        </section>
        <section
          className="py-14 md:py-24 bg-yellow bg-opacity-60"
          id={t('home:contact.id')}
        >
          <div className="mx-auto px-container max-w-container">
            <h2 className="text-headline2 leading-none text-center">
              {t('home:contact.headline')}
            </h2>
            <div className="text-center mt-12">
              <a
                className="inline-block text-action leading-none text-center rounded-full bg-white border-4 border-black border-solid break-words overflow-hidden"
                href={t('home:contact.mailto.url')}
                target="_blank"
                rel="noreferrer"
                sx={{
                  padding: '0.5em 1em',
                }}
              >
                {t('home:contact.mailto.text')}
              </a>
            </div>
            <Markdown
              className="mt-12 text-action leading-none text-center"
              source={t('home:contact.details')}
            />
          </div>
        </section>
      </main>
    </Layout>
  )
}
